import React, {useEffect, useState} from "react";
import './Home.css';
import {usePopup} from '../context/popup.context'

import Tile from "../Components/Tile";
import CBRNDHexImg from '../media/tiles/cbrnd_tile.webp'
// import LVCImg from '../media/tiles/lvc_hex.webp'
import LTEImg from '../media/tiles/ite_tile.webp'
import CATSTile from '../media/tiles/cats_tile.webp'
import VETTile from '../media/tiles/vet_tile.webp'
import JAVTile from '../media/tiles/jav_tile.webp'
import AdvTSTile from '../media/tiles/adv_ts_tile.webp'
import SBSysTile from '../media/tiles/shootback_tile.webp'
import EPANTile from '../media/tiles/epan_tile.webp'
import VehicleImg from '../media/tiles/vehicle_tile.webp'
import ARDSImg from '../media/tiles/ards_tile.webp'
import ASMTITileImg from '../media/tiles/asmti_tile.webp'
// import HomestationTile from '../media/tiles/homestation_tile.webp'
import ILTSTile from '../media/tiles/ilts_tile.webp'
import CTCTile from '../media/tiles/ctc_core2_tile_tile.webp'
// import UrbanLVCTile from '../media/tiles/urbanlvc_tile.webp'
// import NavySynthTile from '../media/tiles/navy_synth_tile.webp'
// import SAFTITile from '../media/tiles/safti_tile.webp'
import MDLVCTile from '../media/tiles/mdlvc_tile.webp'
import SPEARTile from '../media/tiles/spear_tile.webp'
import PATRONTile from '../media/tiles/patron_tile.webp'
import MDTTile from '../media/tiles/mdt_tile.webp'
// import NQSparkTile from '../media/tiles/nqspark_tile.webp'
// import BlueroomTile from '../media/tiles/blueroom_tile.webp'
import BlankTile from '../media/tiles/blank_tile.webp'

const Home = ()=>{
    const {setPopupData} = usePopup();
    const [winWidth, setWinWidth] = useState(window.innerWidth);
    let changeWidth = 700;

    //on page load, turn loading page off
    useEffect(_=>{
        setPopupData({data: '', title: '', visible: false});
        function handleWindowResize(){
            setWinWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleWindowResize);
    }, [setPopupData, winWidth])


    return (
        <div className="homePage page">
            <div className="programTilesCont">
                
                {/* <Tile bgImg={SAFTITile} title="SAFTI City" linkUrl="safti"/> */}
                <Tile bgImg={EPANTile} title="Enhanced Personal Area Network (EPAN)" linkUrl="epan"/>
                <Tile bgImg={VehicleImg} title="Vehicle Kits" linkUrl="vehiclekits"/>
                <Tile bgImg={MDLVCTile} title="Multi-Domain LVC" linkUrl="mdlvc"/>
                <Tile bgImg={CATSTile} title="CATS Metrix" linkUrl="cats"/>
                <br/>
                
                {/* <Tile bgImg={HomestationTile} title="Soldier Kits" linkUrl="manWear"/> */}
                <Tile bgImg={JAVTile} title="Javelin Outdoor Trainer" linkUrl="jot"/>
                <Tile bgImg={SBSysTile} title="Shootback target system" linkUrl="shootback"/>
                {/* <Tile bgImg={VETTile} title="Veteran Employer of Choice" linkUrl="vet_choice" width={23}/> */}
                <Tile bgImg={VETTile} title="Veteran Employer of Choice" linkUrl="vet_choice"/>
                {winWidth <= changeWidth ? <br/> : ""}
                <Tile bgImg={CBRNDHexImg} title="CBRN" linkUrl="cbrnd"/>  
                <Tile bgImg={AdvTSTile} title="Exercise and Advanced Training Services " linkUrl="adv_training"/>
                {/* <Tile bgImg={NavySynthTile} title="Navy Synth" linkUrl="navy"/> */}
                {/* <br/> */}
                {winWidth > changeWidth ? <br/> : ""}
                
                <Tile bgImg={ASMTITileImg} title="ASMTI" linkUrl="asmti"/>
                <Tile bgImg={LTEImg} title="Hybrid Network"  linkUrl="gtra"/>
                {winWidth <= changeWidth ? <br/> : ""}
                <Tile bgImg={ILTSTile} title="Integrated Targetry" linkUrl="ilts"/>
                <Tile bgImg={SPEARTile} title="SPEAR" linkUrl="multidomain_training"/>
                {winWidth > changeWidth ? <br/> : ""}
                {/* <Tile bgImg={HomestationTile}  title="Live Trg in Homestation"/> */}
                <Tile bgImg={CTCTile} title="Army Training" linkUrl="ctc"/>
                {winWidth <= changeWidth ? <br/> : ""}
                {/* <Tile bgImg={BlueroomTile} title="BlueRoom" linkUrl="blueroom"/>
                <Tile bgImg={NQSparkTile} title="NQ SPARK" linkUrl="nqspark"/> */}
                <Tile bgImg={ARDSImg} title="Air Ranges" linkUrl="air_ranges"/>
                <Tile bgImg={PATRONTile} title="Patron Defence" linkUrl="patron_defence"/>
                <br/>

                

            </div>
        </div>
    )
}

export default Home;